import { default as indexE7JNTlYk0sMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/account/custom/index.vue?macro=true";
import { default as packagesWjXTqVYkbTMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/account/custom/packages.vue?macro=true";
import { default as partsPzddXZUfXuMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/account/custom/parts.vue?macro=true";
import { default as customOpihFRdfh0Meta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/account/custom.vue?macro=true";
import { default as indexDeTb6tnfYGMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/account/index.vue?macro=true";
import { default as ratesFVyNy0x1WwMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/account/rates.vue?macro=true";
import { default as subscriptiona4QELi7RUIMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/account/subscription.vue?macro=true";
import { default as accountYeGFrIoAmjMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/account.vue?macro=true";
import { default as _91id_93mnaMS0v1H5Meta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/activite/clients/edit/[id].vue?macro=true";
import { default as indexL1fPlG9y8GMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/activite/clients/index.vue?macro=true";
import { default as _91id_93VUjYBV9ffgMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/activite/clients/show/[id].vue?macro=true";
import { default as _91id_93mV4dO6tEUFMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/activite/commandes/[id].vue?macro=true";
import { default as indexBfJK8gHfHeMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/activite/commandes/index.vue?macro=true";
import { default as vehiculesmykYgwkxFKMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/activite/vehicules.vue?macro=true";
import { default as _91title_93_45_91id_93qJlYlf07IsMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/Boutique/[title]-[id].vue?macro=true";
import { default as indexBmHZBBsNIGMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/Boutique/index.vue?macro=true";
import { default as productsdh3nns11f8Meta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/Boutique/products.vue?macro=true";
import { default as indexSTb4eMHC7OMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/catalog/index.vue?macro=true";
import { default as configurationNDCBoGkSwCMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/catalog/maintenance/configuration.vue?macro=true";
import { default as indextFkgM9pRkAMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/catalog/maintenance/index.vue?macro=true";
import { default as plannJKZcmKQdlMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/catalog/maintenance/plan.vue?macro=true";
import { default as worksiSCEXpuTZUMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/catalog/maintenance/works.vue?macro=true";
import { default as maintenance7DGyGHhHTIMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/catalog/maintenance.vue?macro=true";
import { default as manualsvmUlQ2HvXlMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/catalog/manuals.vue?macro=true";
import { default as timesomKMGXD5VVMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/catalog/times.vue?macro=true";
import { default as catalogRB5wliCmhWMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/catalog.vue?macro=true";
import { default as commandekJ8vB86G57Meta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/commande.vue?macro=true";
import { default as _91tab_93RSSzSU7qhiMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/devis/[tab].vue?macro=true";
import { default as _91id_937ObCDGptnkMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/devis/edit/[id].vue?macro=true";
import { default as _91id_93zT06wkfoP0Meta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/devis/show/[id].vue?macro=true";
import { default as imprimer_45pdftxPCIktkbVMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/imprimer-pdf.vue?macro=true";
import { default as indexQgwvlsu44lMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/index.vue?macro=true";
import { default as login4U4z20F1upMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/login.vue?macro=true";
import { default as panierHF70zbSDGyMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/panier.vue?macro=true";
import { default as indexc45Sr8RrO0Meta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/pneumatique/index.vue?macro=true";
import { default as products0nkr4vA99uMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/pneumatique/products.vue?macro=true";
import { default as pneumatiquejBrEb9YTtWMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/pneumatique.vue?macro=true";
import { default as maintenanceZoIGsBHR4aMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/print/maintenance.vue?macro=true";
import { default as products7bhUXmNnIJMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/products.vue?macro=true";
import { default as signupzAaAJOHm2OMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/signup.vue?macro=true";
import { default as tableau_45de_45bord9RnFEnHhAYMeta } from "/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: accountYeGFrIoAmjMeta?.name,
    path: "/account",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/account.vue"),
    children: [
  {
    name: customOpihFRdfh0Meta?.name,
    path: "custom",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/account/custom.vue"),
    children: [
  {
    name: "account-custom",
    path: "",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/account/custom/index.vue")
  },
  {
    name: "account-custom-packages",
    path: "packages",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/account/custom/packages.vue")
  },
  {
    name: "account-custom-parts",
    path: "parts",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/account/custom/parts.vue")
  }
]
  },
  {
    name: "account",
    path: "",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/account/index.vue")
  },
  {
    name: "account-rates",
    path: "rates",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/account/rates.vue")
  },
  {
    name: "account-subscription",
    path: "subscription",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/account/subscription.vue")
  }
]
  },
  {
    name: "activite-clients-edit-id",
    path: "/activite/clients/edit/:id()",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/activite/clients/edit/[id].vue")
  },
  {
    name: "activite-clients",
    path: "/activite/clients",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/activite/clients/index.vue")
  },
  {
    name: "activite-clients-show-id",
    path: "/activite/clients/show/:id()",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/activite/clients/show/[id].vue")
  },
  {
    name: "activite-commandes-id",
    path: "/activite/commandes/:id()",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/activite/commandes/[id].vue")
  },
  {
    name: "activite-commandes",
    path: "/activite/commandes",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/activite/commandes/index.vue")
  },
  {
    name: "activite-vehicules",
    path: "/activite/vehicules",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/activite/vehicules.vue")
  },
  {
    name: "Boutique-title-id",
    path: "/Boutique/:title()-:id()",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/Boutique/[title]-[id].vue")
  },
  {
    name: "Boutique",
    path: "/Boutique",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/Boutique/index.vue")
  },
  {
    name: "Boutique-products",
    path: "/Boutique/products",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/Boutique/products.vue")
  },
  {
    name: catalogRB5wliCmhWMeta?.name,
    path: "/catalog",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/catalog.vue"),
    children: [
  {
    name: "catalog",
    path: "",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/catalog/index.vue")
  },
  {
    name: maintenance7DGyGHhHTIMeta?.name,
    path: "maintenance",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/catalog/maintenance.vue"),
    children: [
  {
    name: "catalog-maintenance-configuration",
    path: "configuration",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/catalog/maintenance/configuration.vue")
  },
  {
    name: "catalog-maintenance",
    path: "",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/catalog/maintenance/index.vue")
  },
  {
    name: "catalog-maintenance-plan",
    path: "plan",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/catalog/maintenance/plan.vue")
  },
  {
    name: "catalog-maintenance-works",
    path: "works",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/catalog/maintenance/works.vue")
  }
]
  },
  {
    name: "catalog-manuals",
    path: "manuals",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/catalog/manuals.vue")
  },
  {
    name: "catalog-times",
    path: "times",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/catalog/times.vue")
  }
]
  },
  {
    name: "commande",
    path: "/commande",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/commande.vue")
  },
  {
    name: "devis-tab",
    path: "/devis/:tab()",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/devis/[tab].vue")
  },
  {
    name: "devis-edit-id",
    path: "/devis/edit/:id()",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/devis/edit/[id].vue")
  },
  {
    name: "devis-show-id",
    path: "/devis/show/:id()",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/devis/show/[id].vue")
  },
  {
    name: "imprimer-pdf",
    path: "/imprimer-pdf",
    meta: imprimer_45pdftxPCIktkbVMeta || {},
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/imprimer-pdf.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login4U4z20F1upMeta || {},
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/login.vue")
  },
  {
    name: "panier",
    path: "/panier",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/panier.vue")
  },
  {
    name: pneumatiquejBrEb9YTtWMeta?.name,
    path: "/pneumatique",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/pneumatique.vue"),
    children: [
  {
    name: "pneumatique",
    path: "",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/pneumatique/index.vue")
  },
  {
    name: "pneumatique-products",
    path: "products",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/pneumatique/products.vue")
  }
]
  },
  {
    name: "print-maintenance",
    path: "/print/maintenance",
    meta: maintenanceZoIGsBHR4aMeta || {},
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/print/maintenance.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/products.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupzAaAJOHm2OMeta || {},
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/signup.vue")
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    component: () => import("/var/www/vhosts/bgdiff.com/dev-app.bgdiff.com/pages/tableau-de-bord.vue")
  }
]